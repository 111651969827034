
import { defineComponent } from 'vue';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import People from './components/People.vue';

// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
  apiKey: "AIzaSyCj0Hy49cRwRR13TU6guppQ_PNQOMB7Ms8",
  authDomain: "minaapp-d0971.firebaseapp.com",
  databaseURL: "https://minaapp-d0971.firebaseio.com",
  projectId: "minaapp-d0971",
  storageBucket: "minaapp-d0971.appspot.com",
  messagingSenderId: "348247366949",
  appId: "1:348247366949:web:8da69c2fc32a7be8488ecc",
  measurementId: "G-XW9QW3DJWV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default defineComponent({
  name: 'App',
  components: {
    People
  },
  methods: {
    logAnonMessage: function(...params: any) {
      this.trackEvent(...params);
      setTimeout(console.log.bind(console, ...params));
    },
    trackEvent: function(...params: any) {
      logEvent(analytics, [...params].join());
    }
  },
});
