
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia'
import { contentfulStore, localStorageKey, Person } from '../contentful-ad-store';

export default defineComponent({
  name: 'People',
  data() {
    return {
      selectedPersonId: ''
    }
  },
  mounted() {
    const app = this.$root as any;
    const contentInStorage = localStorage.getItem(localStorageKey);

    var runNewQuery = false;

    if (contentInStorage) {
      const data = JSON.parse((localStorage.getItem(localStorageKey) as any));

      if (data && data.expiration && data.expiration > new Date(Date.now()).getTime()) {
        var minutesLeft =  Math.round((((data.expiration - new Date(Date.now()).getTime()) % 86400000) % 3600000) / 60000);
        app.logAnonMessage('Content cached for ' + minutesLeft + ' more minute' + (minutesLeft == 1 ? "" : "s"));

        this.patchState(data.data);
      } else {
        // Data has been stored too long, query it again
        localStorage.removeItem(localStorageKey);
        runNewQuery = true;
      }
    } else {
      runNewQuery = true;
    }

    if (runNewQuery) {
      this.queryPeople();
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          return;
        }

        entry.target.classList.remove('animate');
      });
    });

    var animatedElementIds = [ 
      'select-member',
      'member-1',
      'email-section',
      'social-section'
    ];

    animatedElementIds.forEach(elementId => {
      var element = document.getElementById(elementId);
      if (element != null) {
        observer.observe(element);
      }
    });
  },
  methods: {
    ...mapActions(contentfulStore, { 
      queryPeople: 'queryPeople',
      patchState: 'patchState'
    }),
    selectPerson(personId: string) {
      this.selectedPersonId = personId;
    },
    unselectPerson() {
      this.selectedPersonId ='';
    },
    getFirstNameOnly(name: string) {
      const matchResult = name.match(/^\S+/);
      if (matchResult) {
        return matchResult[0];
      } else {
        return name;
      }
    },
    copyEmailAddress: function() {
      var app = this.$root as any;
      var emailAddress = this.selectedPersonEmail;
      var emailAddressAnchor = document.getElementById("email-address") as any;

      navigator.clipboard.writeText(emailAddress).then(function() {
        app?.trackEvent('Copy email address ' + emailAddress);

        emailAddressAnchor.innerHTML = "copied!";

        setTimeout(function() {
          emailAddressAnchor.innerHTML = emailAddress;
        }, 600);
      });
    },
    openUrl: function(path: string|undefined) {
      (this.$root as any)?.trackEvent('Open link ' + path);
      window.open(path);
    }
  },
  computed: {
    ...mapState(contentfulStore, {
      storeState: entry => entry,
      daysSinceQuery: entry => entry.daysSinceQuery,
    }),
    people(): Person[] {
      var self = this as any;
      return self.storeState.people as Person[];
    },
    selectedPerson() {
      var self = this as any;
      return self.people.find((person: Person) => person.id === self.selectedPersonId);
    },
    selectedPersonName() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.name : '';
    },
    selectedPersonImage() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.image : '';
    },
    selectedPersonHelloText() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.helloText : '';
    },
    selectedPersonEmail() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.email : '';
    },
    selectedPersonLinkedInUrl() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.linkedInUrl : '';
    },
    selectedPersonTwitterUrl() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.twitterUrl : '';
    },
    selectedPersonIntroParagraphs() {
      var self = this as any;
      return self.selectedPerson ? self.selectedPerson.introParagraphs : [];
    }
  }
});
