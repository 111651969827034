import { PiniaCustomStateProperties, defineStore } from 'pinia'
import { client } from './contentful-ad-api';

export const localStorageKey: string = 'asad-dowling-store';
export const personContentTypeId: string = 'person';

export interface Person {
  id: string;
  name: string;
  image: string;
  helloText: string;
  email: string;
  linkedInUrl: string;
  twitterUrl: string;
  introParagraphs: any[];
}

export const contentfulStore = defineStore('contentful-ad-api-store', {
    state: () => ({
      people: [] as Person[],
      lastQuery: new Date(-8640000000000000) as any //min date
    }),
    getters: {
      peopleEntry(state) {
        return state.people;
      },
      daysSinceQuery(state) {
        return Math.ceil((state.lastQuery.getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24));
      }
    },
    actions: {
      patchState(newState: any & PiniaCustomStateProperties<{
            person: Array<{
              id: string;
              name: string;
              image: string;
              helloText: string;
              email: string;
              linkedInUrl: string;
              twitterUrl: string;
              introParagraphs: any;
            }>;
            lastQuery: any; //min date
        }>) {
        this.$state = newState;
      },
      queryPeople() {
        const self = this;

        client.getEntries({
          content_type: personContentTypeId
        })
        .then((response: { items: any; }) => {
          self.people = [];
          response.items.forEach((item: any) => self.people.push(getContent(item)));

          self.lastQuery = new Date(Date.now());
          localStorage.setItem(localStorageKey, JSON.stringify({
            data: self.$state,
            expiration: new Date(Date.now()).getTime() + 1000 * 60 * 60
          }));
        })
        .catch(console.error)

        function getContent(item: any)
        {
          const person: Person = {
            id: item.sys.id,
            name: item.fields.personName,
            image: item.fields.personImage.fields.file.url,
            helloText: item.fields.personHelloText,
            email: item.fields.personEmail,
            linkedInUrl: item.fields.personLinkedInUrl,
            twitterUrl: item.fields.personTwitterUrl,
            introParagraphs: []
          };

          const intro = item.fields.personIntro;
          intro.content.forEach((introItem: { content: { value: any; }[]; }) => {
            person.introParagraphs.push(introItem.content[0].value)
          });

          return person;
        }
      }
    }
  })