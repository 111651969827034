import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

const pinia = createPinia();
const app = createApp(App);

app.config.globalProperties.$isDebugging = process.env.NODE_ENV === 'development';

app.use(pinia);
app.mount('#app');